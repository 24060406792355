<template>
  <ProfessionalLoginTemplate>
    <h3>Login</h3>
    <ErrorNotification :errors="errors" />
    <form ref="loginForm" novalidate="true" @submit.prevent="step === 1 ? next() : checkForm()" class="login-form margin-top-thirty">
      <div class="email-container">
        <ion-icon v-if="step === 2" name="md-arrow-back" class="back-icon" @click="back" />
        <div class="prime-form-group" :class="{ 'margin-left-thirty': step === 2 }">
          <ion-icon name="md-mail" class="input-icon" />
          <input name="username" class="form-control" :disabled="step === 2" type="text" v-model="username" placeholder="Email Address" />
        </div>
      </div>
      <div v-if="step === 2" class="prime-form-group">
        <ion-icon name="md-lock" class="input-icon" />
        <input name="password" class="form-control" type="password" ref="password" v-model="password" placeholder="Password" />
      </div>
      <p v-if="step === 2" class="forgot-password">
        Forgot Password?
        <router-link :to="{ name: 'forgotPassword' }">Reset it now</router-link>
      </p>
      <PrimeButton :mt="20" block :pending="isLoading">{{ step === 1 ? "Next" : "Login" }}</PrimeButton>
    </form>
  </ProfessionalLoginTemplate>
</template>
<script>
import { addIcons } from "ionicons";
import mail from "@/assets/mail-outline.svg";
import arrowBack from "@/assets/arrow-back-circle-outline.svg";
import lock from "@/assets/lock-closed-outline.svg";
import authApi from "@/services/AuthApi";
import isValidEmail from "@/utils/FormHelpers/isValidEmail";
import ErrorNotification from "@/components/Global/ErrorNotification";
import ProfessionalLoginTemplate from "@/components/Login/Templates/TemplateLoginProfessional";

addIcons({
  "md-mail": mail,
  "md-arrow-back": arrowBack,
  "md-lock": lock
});

export default {
  name: "FormLoginProfessional",
  components: {
    ErrorNotification,
    ProfessionalLoginTemplate
  },
  data() {
    return {
      errors: [],
      step: 1,
      username: "",
      password: "",
      isLoading: false
    };
  },
  methods: {
    async next() {
      this.errors = [];

      if (!this.username) {
        this.errors.push("Username required.");
        return;
      }

      if (!isValidEmail(this.username)) {
        this.errors.push("Username must be a valid email.");
        return;
      }
      const type = await authApi.getLoginType(this.username);
      switch (type) {
        case "token":
          if (this.$route.query.goto) {
            window.localStorage.setItem("login.goto", this.$route.query.goto);
          }
          authApi.executeSSO(this.username);
          this.isLoading = true;
          break;
        case "password":
          this.step++;
          this.$nextTick(() => {
            this.$refs.password.focus();
          });
          break;
        default:
          window.location = "https://apps.lumeris.com";
      }
    },
    back() {
      this.errors = [];
      this.step--;
    },
    login: async function() {
      this.isLoading = true;

      try {
        const data = await authApi.signIn(this.username, this.password);

        if (data.access_token) {
          this.setAuthTokens(data);
          const goto = this.$route.query.goto ? { goto: this.$route.query.goto } : {};
          this.$router.push({ path: "/", query: goto });
        } else {
          khanSolo.error("Authorization token not included in the response.");
          this.errors.push("There was a server error. Please try again.");
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        this.errors.push(error.response.data.message);
      }
    },
    checkForm() {
      this.errors = [];

      if (!this.username) {
        this.errors.push("Username required.");
      }
      if (!this.password) {
        this.errors.push("Password required.");
      }
      if (!this.errors.length) {
        this.login();
      }
    }
  }
};
</script>

<style scoped>
.email-container {
  position: relative;
}
.prime-form-group {
  position: relative;
}
.form-control {
  padding-left: 40px;
}
.back-icon {
  cursor: pointer;
  font-size: 26px;
  color: var(--ion-color-primary);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.input-icon {
  font-size: 24px;
  color: var(--ion-color-medium-shade);
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.input-icon {
  font-size: 24px;
  color: var(--ion-color-medium-shade);
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}
:disabled + .input-icon {
  color: black;
}
.forgot-password {
  font-size: 12px;
  margin-bottom: 0;
  text-align: right;
  color: var(--ion-color-medium-shade);
}
</style>
