<template>
  <div class="login-grid-container">
    <div class="login-grid-welcome">
      <div class="full-width">
        <h1>The next generation of personal care.</h1>
      </div>
      <div class="full-width margin-top-twenty">
        <p>
          The solution primary care practices need to bring care teams and patients together.
        </p>
      </div>
      <div class="power-container">
        <span style="vertical-align: middle">Powered by</span>
        <img src="@/assets/lumeris-logo-white.svg" />
      </div>
    </div>
    <div class="login-grid-form-container">
      <div class="flex-v-spacer"></div>
      <div class="login-logo-container">
        <img src="@/assets/logo.svg" />
      </div>
      <div class="login-form-main">
        <slot></slot>
      </div>
      <div class="flex-v-spacer"></div>
      <div class="login-footer pad-top-ten">
        <FooterCompliance />
      </div>
    </div>
  </div>
</template>

<script>
import FooterCompliance from "@/components/Global/FooterCompliance";

export default {
  name: "ProfessionalLoginTemplate",
  components: {
    FooterCompliance
  }
};
</script>

<style scoped>
.login-grid-container {
  height: 100vh;
  display: flex;
  background: #ffffff;
}
.login-grid-welcome {
  position: relative;
  flex-grow: 1;
  background: url(../../../assets/MyPCP-BG-with-overlay-2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #ffffff;
  padding: 0 15% 0 10%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
}
.login-grid-welcome h1 {
  font-size: 58px !important;
}
.login-grid-welcome p {
  font-size: 16px;
  font-weight: 400;
}
.login-logo-container {
  display: flex;
  width: 100%;
}
.login-logo-container img {
  width: 330px;
  margin: 0 auto 30px;
}
.power-container {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  font-size: 12px;
  text-align: center;
}
.power-container img {
  margin: 0 10px;
  vertical-align: middle;
}
.login-grid-form-container {
  background: #ffffff;
  display: flex;
  flex: 0 0 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.login-form-main {
  width: 290px;
  text-align: center;
}

@media (max-width: 768px) {
  .login-grid-welcome {
    display: none;
  }
  .login-grid-form-container {
    flex: 0 0 auto !important;
  }
}

.login-footer {
  display: block;
  margin-bottom: 30px;
}
</style>
