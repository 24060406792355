<template>
  <div class="login-grid-container">
    <div class="login-grid-form-container">
      <div class="flex-v-spacer"></div>
      <div class="login-logo-container">
        <img src="@/assets/logo.svg" />
      </div>
      <div class="login-form-main">
        <slot></slot>
      </div>
      <div class="flex-v-spacer"></div>
      <div class="login-footer pad-top-twenty">
        <FooterCompliance></FooterCompliance>
      </div>
    </div>
  </div>
</template>

<script>
import FooterCompliance from "@/components/Global/FooterCompliance";

export default {
  name: "PatientLoginTemplate",
  components: {
    FooterCompliance
  }
};
</script>

<style scoped>
.login-grid-container {
  height: 100vh;
  display: flex;
  background: #ffffff;
}
.login-logo-container {
  display: flex;
  width: 100%;
}
.login-logo-container img {
  width: 250px;
  margin: 0 auto 30px;
}
.login-grid-form-container {
  background: #ffffff;
  display: flex;
  flex: 0 0 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.login-form-main {
  width: 290px;
  text-align: center;
}

@media (max-width: 768px) {
  .login-grid-form-container {
    flex: 0 0 auto !important;
  }
}

.login-footer {
  display: block;
  margin-bottom: 30px;
}
</style>
