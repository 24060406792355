<template>
  <div class="login-footer-container">
    <router-link to="/terms-conditions">Terms & Conditions</router-link>
    <br />
    Copyright © 2010-{{ new Date().getFullYear() }} Lumeris. All rights reserved. <br />
    LumerisEngage&trade; is a trademark of Lumeris.
  </div>
</template>

<script>
export default {
  name: "FooterCompliance"
};
</script>

<style scoped>
.login-footer-container {
  text-align: center;
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-medium);
  line-height: 1.8;
}
.login-footer-container a {
  color: var(--ion-color-medium);
  text-decoration: none;
}
.login-footer-container a:hover {
  color: var(--ion-color-primary);
}
</style>
