<template>
  <PatientLoginTemplate>
    <ErrorNotification :errors="errors" />
    <ion-spinner v-if="isVerifying" name="dots" />
    <div v-if="!isVerifying">
      <p v-if="!tokenIsValid">{{ pageCopy.invalidTokenMessage }}</p>
      <div v-if="tokenIsValid">
        <h4>{{ pageCopy.header }}</h4>
        <p id="patientLoginHeaderText" v-dompurify-html="pageCopy.headerText" />
        <form id="patientPasswordForm" ref="loginForm" v-if="tokenIsValid" @submit.prevent="checkForm" novalidate="true" class="login-form">
          <label for="password"><h6>Date of Birth</h6></label>
          <input
            name="password"
            class="form-control"
            id="password"
            type="text"
            inputmode="numeric"
            v-model="password"
            v-mask="{
              mask: ['99/99/9999'],
              placeholder: 'MM/DD/YYYY',
              clearMaskOnLostFocus: false
            }"
          />
          <input name="token" class="form-control" id="token" type="hidden" v-model="zeusToken" />
          <button class="prime-button button-block margin-top-ten" :class="{ 'button-pending': isLoading }">
            <span>Login</span>
            <ion-spinner name="dots" duration="1200" />
          </button>
        </form>
      </div>
    </div>
  </PatientLoginTemplate>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import ErrorNotification from "@/components/Global/ErrorNotification";
import PatientLoginTemplate from "@/components/Login/Templates/TemplateLoginPatient";

export default {
  name: "FormLoginPatient",
  components: {
    ErrorNotification,
    PatientLoginTemplate
  },
  data() {
    return {
      errors: [],
      password: "",
      isLoading: false,
      isVerifying: true,
      tokenIsValid: false,
      pageCopy: this.$languagePack.patientLogin
    };
  },
  created() {
    this.tokenIsValid = this.verifyToken();
  },
  methods: {
    async verifyToken() {
      this.isVerifying = true;
      const payload = this.zeusToken;
      const method = "post";
      const path = document.config.validateZeusToken;
      const headers = { "Content-Type": "text/plain" };

      try {
        const response = await httpSend({
          path,
          method,
          payload,
          headers
        });
        this.tokenIsValid = response.data;
      } catch (error) {
        this.isVerifying = false;
        this.handleApiError(error);
      } finally {
        this.isVerifying = false;
      }
    },
    login: async function() {
      this.isLoading = true;
      const payload = new FormData(this.$refs.loginForm);
      const method = "post";
      const path = document.config.authApiBene;
      const headers = { "Content-Type": "multipart/form-data" };

      try {
        const response = await httpSend({
          path,
          method,
          payload,
          headers
        });

        if (response.data.access_token) {
          response.data.zeus_token = this.zeusToken;
          this.setAuthTokens(response.data);
          this.$router.push({ name: "patientDefault" });
        } else {
          this.error.push(this.pageCopy.error.serverError);
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        this.handleApiError(error.response.data);
      }
    },
    getPayload() {
      const payload = new FormData();
      payload.set("password", this.formattedPassword);
      payload.append("token", this.zeusToken);

      return payload;
    },
    handleApiError(error) {
      const statusMessages = {
        401: this.pageCopy.errors.status401,
        500: this.pageCopy.errors.status500
      };
      const errorMessage = statusMessages[error.status] || this.pageCopy.errors.serverError || error.message;
      this.errors.push(errorMessage);
    },
    checkForm: function(e) {
      this.errors = [];
      const parsedPassword = new Date(this.password);
      if (!this.password) {
        this.errors = true;
        this.errors.push(this.pageCopy.errors.wrongPassword);
      }
      if (isNaN(parsedPassword)) {
        this.errors.push(this.pageCopy.errors.passwordMissing);
      }
      if (!this.errors.length) {
        this.login();
      }
      e.preventDefault();
    }
  }
};
</script>

<style scoped>
#patientPasswordForm input {
  text-align: left;
}
#patientPasswordForm input::-webkit-inner-spin-button {
  display: none;
}
#patientPasswordForm input::-webkit-calendar-picker-indicator {
  display: none;
}
#patientPasswordForm input::-webkit-input-placeholder {
  visibility: hidden !important;
}
#patientPasswordForm label {
  text-align: left;
}
#patientLoginHeaderText {
  margin: auto 2em;
}
#password {
  text-transform: uppercase;
}
</style>
