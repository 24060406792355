<template>
  <LoginFormPatient v-if="isPatient" />
  <LoginFormProfessional v-else />
</template>
<script>
import LoginFormProfessional from "@/components/Login/LoginForms/FormLoginProfessional";
import LoginFormPatient from "@/components/Login/LoginForms/FormLoginPatient";

export default {
  name: "LoginView",
  components: {
    LoginFormProfessional,
    LoginFormPatient
  },
  created() {
    if (this.$route.query.token) {
      this.zeusToken = this.$route.query.token;
    }
  }
};
</script>
