<template>
  <div v-if="errors.length">
    <div v-for="error in errors" :key="error" class="error-notification">
      <WarningSvg />
      <ion-text>{{ error }}</ion-text>
    </div>
  </div>
</template>

<script>
import WarningSvg from "@/components/Global/Icons/IconWarning";

export default {
  name: "ErrorNotification",
  components: {
    WarningSvg
  },
  props: {
    errors: Array
  }
};
</script>
